<template>
  <div class="OrderDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />
          <q-btn icon="business" class="buttonsBar" @click="openHistoric" />
          <q-btn
            icon="table_rows"
            class="buttonsBar"
            v-if="viewTable"
            @click="viewTable = !viewTable"
          />
          <q-btn
            icon="table_chart"
            class="buttonsBar"
            v-if="!viewTable"
            @click="viewTable = !viewTable"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: 80vh;">
      <div v-if="viewTable">
        <q-card class="q-ma-sm" v-if="order">
          <q-card-section>
            <div class="row justify-start">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Pedido: </span>
                <span>{{ order.orderNumber }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Empresa: </span>
                <span>{{ `${order.branchCode} - ${order.branchName}` }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Municipio: </span>
                <span>{{
                  `${order.branchCity || ""} - ${order.branchUf || ""}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Forn.: </span>
                <span>{{ order.supplierName }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Municipio Forn.: </span>
                <span>{{
                  `${order.supplierCity || ""} - ${order.supplierUf || ""}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Data da SC: </span>
                <span>{{
                  `${formatString(order.requestDate || "", "date")}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Dt. Pedido: </span>
                <span>{{ formatString(order.issueDate, "date") }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Solicitante: </span>
                <span>{{ order.requester }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Comprador: </span>
                <span>{{ order.buyer }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Centro de Custo: </span>
                <span>{{ order.costCenter }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Condição Pagto: </span>
                <span>{{ order.faymentForm }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Tipo Frete: </span>
                <span>{{ order.freightType }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor Frete: </span>
                <span>{{
                  formatString(
                    order.freightAmount || 0,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor : </span>
                <span>{{
                  formatString(
                    order.netAmount || 0,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Desconto: </span>
                <span>{{
                  formatString(
                    order.amountDiscount || 0,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor Total: </span>
                <span>{{
                  formatString(
                    order.amountOfOrder,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>
            </div>
          </q-card-section>
        </q-card>
        <DataTable
          v-if="order"
          :data="items"
          :columns="columns"
          :gridMobile="false"
          hidePagination
          class="q-mx-sm"
          style="margin-bottom: 50px ;"
          :currency="order.currency"
        />
      </div>
      <div v-else>
        <productsCard :data="order" />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-mb-xl">
      <div class="col-auto">
        <q-btn color="green" icon="check" label="Aprovar" @click="approve" />
      </div>
      <div class="col-auto">
        <q-btn color="red" icon="block" label="Rejeitar" @click="reject" />
      </div>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />
    <PurchasesHistoric ref="PurchasesHistoric" />

    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Pedido de Compra</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import DataTable from "@/components/DataTable";
import productsCard from "./productsCard";
import ApprovalHierarchy from "./ApprovalHierarchy";
import PurchasesHistoric from "./PurchasesHistoric";
import formatString from "@/helpers/formatString";

export default {
  components: {
    DataTable,
    productsCard,
    ApprovalHierarchy,
    PurchasesHistoric
  },
  data() {
    return {
      modalReason: false,
      descriptionRejection: null,
      order: null,
      items: null,
      viewTable: true,
      columns: [
        {
          field: "item",
          label: "Item",
          align: "center",
          sortable: true
        },
        {
          field: "productDescription",
          label: "Produto",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "um",
          label: "UM",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "quantity",
          label: "Qt.",
          align: "center",
          type: "number",
          sortable: true
        },
        {
          field: "unitaryValue",
          label: "Vlr.Unit.",
          align: "center",
          type: "currency",
          sortable: true
        },
        {
          field: "unitaryDiscount",
          label: "Desc.Unit.",
          align: "center",
          type: "currency",
          sortable: true
        },
        {
          field: "amount",
          label: "Vlr.Tot.",
          align: "center",
          type: "currency",
          sortable: true
        },
        {
          field: "ipi",
          label: "IPI",
          align: "center",
          type: "currency",
          sortable: true
        },
        {
          field: "valueLastPurchase",
          label: "Valor Últ. Entrada",
          align: "center",
          type: "currency",
          sortable: true
        },
        {
          field: "deliveryDate",
          label: "Dt. Entrega",
          align: "center",
          type: "date",
          sortable: true
        },
        {
          field: "descriptionClass",
          label: "Classe Orçamentária",
          align: "left",
          type: "string",
          sortable: true
        }
      ]
    };
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("purchaseOrders", [
      "getOrderById",
      "approvesRejectsPurchaseOrder"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    formatString,
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.order.approvalHierarchy);
    },
    openHistoric() {
      this.$refs.PurchasesHistoric.open(this.order);
    },
    approve() {
      this.sendResponse(this.order, "approved", "APROVAR", "text-green");
    },
    reject() {
      this.modalReason = true;
    },
    sendResponse(order, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Pedido ${order.orderNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            const reasonReject =
              response === "rejected" ? this.descriptionRejection : "approved";
            await this.approvesRejectsPurchaseOrder({
              order,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "orders" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    confirmReject() {
      if (
        String(this.descriptionRejection).length < 8 ||
        this.descriptionRejection === null ||
        this.descriptionRejection === ""
      ) {
        this.$q.dialog({
          title: "Cicopal Aprova",
          message: "Favor detalhar o motivo da Rejeição deste pedido!"
        });

        return;
      }

      this.sendResponse(this.order, "rejected", "REJEITAR", "text-red");
    }
  },
  async created() {
    try {
      const orders = await this.getOrderById(this.orderId);
      const items = orders?.items.map(item => ({
        ...item,
        descriptionClass:
          item.classe == undefined ? "-" : `${item.classe} - ${item.classeName}`
      }));

      this.items = items;
      this.order = orders;
    } catch (error) {
      console.log(error.message);
      this.$router.back();
    }
  }
};
</script>
<style lang="scss">
.OrderDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
